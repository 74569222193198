import React from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import projects from "../../Data/Projects.json";
import "../../App.css";

function ProjectPage() {
  const { id } = useParams();
  const project = projects.find((proj) => proj.id === id);

  if (!project) {
    return (
      <Container fluid className="home-section" id="home">

        <header>
          <nav>
            <ul>
              <li><Link to="/home">Home</Link></li>
              <li className="active-page">(here lost)</li>
            </ul>
          </nav>
        </header>
        
        <h1>Project Not Found</h1>
        <Link to="/">Back to Projects</Link>
      </Container>
    );
  }

  return (
    <Container fluid className="home-section" id="home">
      <header>
        <nav>
          <ul>
            <li><Link to="/home">Home</Link></li>
            <li className="active-page">(here)</li>
            <li><Link to="/project">Projects</Link></li>
          </ul>
        </nav>
        <h1>{project.name}</h1>
        <img
          src={project.heroImage}
          alt={`${project.name} hero`}
          className="hero-image"
        />
      </header>

      <main>
      <div className="project-details">
          <h1>{project.title}</h1>
          <p><strong>Year:</strong> {project.year}</p>
          <p><strong>Medium:</strong> {project.tags}</p>
          <p>{project.description}</p>
        </div>

        {/* Gallery (Images, Video, Audio) */}
        <div className="gallery">
          {project.gallery.map((item, index) => (
            <div key={index} className="gallery-item">
              {/* Image with optional caption */}
              {item.type === "image" && (
                <div className="media-wrapper">
                  <img src={item.src} alt={`Gallery item ${index + 1}`} loading="lazy"/>
                  {item.caption && <p className="caption">{item.caption}</p>}
                </div>
              )}

              {/* Video with optional caption */}
              {item.type === "video" && (
                <div className="media-wrapper">
                  <video controls>
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {item.caption && <p className="caption">{item.caption}</p>}
                </div>
              )}

              {/* Audio with optional caption */}
              {item.type === "audio" && (
                <div className="media-wrapper">
                  <audio controls>
                    <source src={item.src} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  {item.caption && <p className="caption">{item.caption}</p>}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="links">
          {project.links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="link-item"
            >
              {link.site}
            </a>
          ))}
        </div>

      </main>
      <br></br>
      <br></br>
      </Container>
  );
}

export default ProjectPage;
