import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import Navbar from "../Navbar";
import { Card } from "react-bootstrap";
import Vimeo from '@u-wave/react-vimeo';


function LivingStatues() {

  return (
    <>
    <Navbar />
    <Container fluid className="project-section">
      <div style={{ maxWidth: 600, minWidth: 200, marginLeft: "auto", marginRight: "auto" }}>
        <img src={require('../../Assets/LivingstatuesWebsite.gif')} alt="intro" height="auto" width="100%"></img>
      </div>

      <p id="project-section-wip"></p>
      <p><i>Downtown Living Statues</i> are a series of digital interactive installations.<br></br>
      The project is a collaboration with the community and a temporal mirror of its inhabitants.<br></br> 
      </p>
      <p><a href="https://www.instagram.com/modeaxe">Contact The Artist</a></p>
      <p><a href="https://github.com/ModeAxe/living-statues">Project Source Archive</a></p><br></br>
      <p>Presented By: <a href="https://publicartfutureslab.com/">Public Art Futures Lab</a> & <a href="https://aeatlanta.com/">A&E Atlanta</a> </p>
    </Container>
    <Container fluid>
  </Container>    
    </>

  );
}

export default LivingStatues;
