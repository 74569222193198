import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectSections from "../../Data/Projects.json";
import images from "../../Assets/Projects/index";
import { Link } from "react-router-dom";

const STYLES = { videoIframeContainer: { padding: '100% 0 0 0', position: 'relative', }, videoIframe: { position: 'absolute', top: "15%", left: 0, width: '100%', height: "60%" } }

function Projects() {

  const [currentProject, setCurrentProject] = useState("");
  const [singleProject, showSingleProject] = useState(false);

  const showProject = (index) => {
    setCurrentProject(index);
    showSingleProject(true);
  };

  const hideSingleProject = () => {
    showSingleProject(false);
  };

  return (
    <>
    <Container fluid className="home-section" id="home">
    
      <header>
        <nav>
          <ul>
            <li><Link to="/home">Home</Link></li>
            <li className="active-page">(SELECT Projects)</li>
          </ul>
        </nav>
      </header>

      <main>
        <ul className="project-list">
            {ProjectSections.map((project, index) => (
              <li key={index} className="project-item">
                  
                <Link to={`/project/${project.id}`}>
                  {project.name} <span className="year">{project.year}</span> - {project.tags}
                  <img className="tooltip-img" src={project.thumbnail} alt={project.name} loading="lazy" />
                </Link>
              
              </li>
            ))}
          </ul>
      </main>      
                <div className="tooltip">
                  <img src={images["birds"]} />
                </div>
      <Container>
      </Container>
    </Container>
    <Container fluid>
  </Container>    
    </>

  );
}

export default Projects;
