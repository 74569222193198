import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import plants from "../../Assets/PLANTS v1-Test.pdf";

function Home() {
	const [mouseData, setMouseData] = useState({ x: 0, y: 0 });
	const canvasRef = useRef(null);
	const [canvasCTX, setCanvasCTX] = useState(null);
	const [color, setColor] = useState('#333333');
	const [size, setSize] = useState(3);
 
	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;
		setCanvasCTX(ctx);
	}, [canvasRef]);
 
	const SetPos = (e) => {

    // const ctx = canvasCTX;
    // ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    
		setMouseData({
			x: e.clientX,
			y: e.clientY
		});
    Draw(e);
	};
 
	const Draw = (e) => {
		//if (e.buttons !== 1) return;
		const ctx = canvasCTX;
		ctx.beginPath();
		ctx.moveTo(mouseData.x, mouseData.y);
		setMouseData({
			x: e.clientX,
			y: e.clientY
		});
		ctx.lineTo(e.clientX, e.clientY);
		ctx.strokeStyle = color;
		ctx.lineWidth = size;
		// Set the line cap to round
		ctx.lineCap = 'round';
		ctx.stroke();
	};

  return (
    <>
      <div
        style={{
          mixBlendMode: "multiply",
          display: "block",
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 9999,
          pointerEvents: 'none',          
        }} 
      >
        <canvas
          ref={canvasRef}
          onMouseEnter={(e) => SetPos(e)}
          onMouseDown={(e) => SetPos(e)}
          onMouseMove={(e) => Draw(e)}
        ></canvas>
      </div>
      
      <Container fluid 
        className="home-section" id="home" 
        onMouseEnter={(e) => SetPos(e)}
        onMouseDown={(e) => SetPos(e)}
        onMouseMove={(e) => Draw(e)}
      >
      <header>
        <nav>
          <ul>
            <li className="active-page">(Here)</li>
            <li><Link to="/project">Projects</Link></li>
          </ul>
        </nav>
      </header>        
        <Container className="home-content">
          <Row>
          <Col md = {{span: 8, order:1}} xs={{span: 12, order:2}} className="home-about-description">
            <p>
              Greetings, my name is Elijah
            </p>
            <p>
              I'm a software engineer, artist, and designer <br></br>
              This website used to be ironic and self-aware in a sort of tongue-in-cheek way, but I changed it.<br></br>
            </p>
            <p>
            Here's a brief, sincere list of things instead:
              <ul>
              <li>
                When I'm not SWE-ing (day job), I like to work on projects using 3D, VR, AR, Video & Data  
              </li>                
              <li>
                You can find <Link to="/project">some of my projects here</Link> and others on a hard drive somewhere in my apartment
              </li>
              <li>
                Feel free to reach me via <a href="mailto:ez2294@columbia.edu">email</a>, <a href="https://www.instagram.com/modeaxe">instagram</a> or <a href="https://www.linkedin.com/in/elijahzulu/"> linkedin</a>
              </li>
              <li>
                I am the inventor of this combination of emojis (&#128016;&#128016;&#128016;&#128016;&#x1FAE1;) I call it the four goat salute. I think of it and a celebration of community and connection— an appreciation of others. You are allowed to use it
              </li>
              <li>
                I also occasianally collect field recordings and create ambient music under the pseudonym <a href="https://open.spotify.com/artist/703H847RxODEIJpgH4ugIg">omedexa</a> (i don't know what it means)
              </li>
              <li>
                If I'm not doing any of those things, I'm probably taking <a href={plants} target="_blank" rel="noreferrer noopener">pictures of plants</a>
              </li>
              {/* <li>
                In 2020, I had a series of dreams that I couldn't stop thinking about until I recreated them using a computer. Computers can do pretty cool things. <a href="https://www.elijahzulu.com/GalleryWEBGL/">First dream.</a> <a href="https://www.elijahzulu.com/avemaria/">Second dream.</a>
              </li> */}
            </ul>              
            </p>
              I hope you have a great day &lt;3
            <p>
              
            </p>       
          </Col>       
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Home;